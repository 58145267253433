import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { quickActions } from "../../utilities/dummyData";

import { Boxx } from "../../components/Temps"; /*

import ChangePinModal from "../Settings/pages/updateAccount/components/ChangePinModal";
import TransferTemp from "./temps/transfer/transfer";
import UtilityTemp from "./temps/utility/utility";
import AirtimeTemp from "./temps/airtime/airtime";
import BettingTemp from "./temps/betting/betting";
import DataTemp from "./temps/data/data";
import CableTemp from "./temps/cable/cable";
import WithrawalTemp from "./temps/widthrawal/withrawal"; */
import { ConfirmationDialog } from "../../components/Modal/Dialog/ConfirmationDialog";

const QuickActions = () => {
  const [card, setCard] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    card === "" ? setOpen(false) : setOpen(true);
  }, [card]);

  /*  const temps = {
    transfer: <TransferTemp close={() => setCard("")} />,
    cable: <CableTemp close={() => setCard("")} />,
    utility: <UtilityTemp close={() => setCard("")} />,
    airtime: <AirtimeTemp close={() => setCard("")} />,
    withdrawal: <WithrawalTemp close={() => setCard("")} />,
    betting: <BettingTemp close={() => setCard("")} />,
    data: <DataTemp close={() => setCard("")} />,
  }; */

  const display = () =>
    quickActions.map((item) => Boxx({ item, setCard, setOpen }));

  return (
    <Box className="d-flex justify-content-between flex-wrap my-4">
      {display()}
      {/* 
      <ChangePinModal state={open}>{temps[card.name]}</ChangePinModal> */}
      <ConfirmationDialog
        openDialog={open}
        onClose={() => setOpen(false)}
        setOpenDialog={setOpen}
      />
    </Box>
  );
};

export default QuickActions;
