import { debounce, isEmpty } from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Divider from "../../components/Divider/Divider";
import Layout from '../../../components/shared/Layout';
import Payment from "../../components/TerminalComponents/Payment";
import SubTotal from "../../components/TerminalComponents/SubTotal";
import TerminalAddress from "../../components/TerminalComponents/TerminalAddress";
import {
  allterminalTypes,
  getUserKycLevel,
  requestTerminal,
  validatePin,
  walletDetails,
} from "../../plugins/urls";
import { formatDonation, sentenceCaps } from "../../utilities/stringOperations";
import ChangePinModal from "../Settings/pages/updateAccount/components/ChangePinModal";

import { TopUpModal } from "../../pages/Settings/pages/updateAccount/components/TopUpModal";
import { apiCall } from "../../utilities/apis";
import ConfirmTransfer from "../Dashboard/temps/ConfirmTransfer";
import ErrorTemp from "../Settings/pages/updateAccount/components/errorTemp";
import SuccessTemp, {
  FailedTemp,
} from "../Settings/pages/updateAccount/components/successTemp";
import { Slide, toast } from "react-toastify";
import axios from "axios";
import MerchantForm from "../../components/TerminalComponents/MerchantForm";
import { getUser } from "../../utilities/objectOperations";
import Swal from "sweetalert2";

export const AddTerminalContext = createContext();

const AddTerminal = () => {
  const { otherDetails, posUserProfile, ...user } = getUser();
  const navigate = useNavigate();
  const [state, setState] = useState({
    step: "select",
    buttonValue: "Continue",
    accountName: "",
    organizationName: `${sentenceCaps(otherDetails?.organisationName)}`,
    accountToDebit: "",
    settlementAccount: "",
    deliveryAddress: `${sentenceCaps(
      `${otherDetails?.organizationAddress ?? posUserProfile?.officeAddress ?? user?.address}, ${
        otherDetails?.organizationCity ?? user?.city
      }, ${otherDetails?.organizationState ?? user?.state ?? user?.district}.`
    )}`,
    fullOrPartPayment: "",
    partPaymentAmount: "",
    subTotal: 0,
    terminalTypes: [],
    loading: false,
  });

  const [showTopUp, setShowTopUp] = useState({ show: false, frame: "topUp" });
  const [currentType, setCurrentType] = useState({});
  const [currentQuantity, setCurrentQuantity] = useState(1);
  const [subtotal, setSubtotal] = useState(1);
  const [method, setMethod] = useState("");
  const [partPayment, setPartPayment] = useState();
  const [reset, setReset] = useState(false);
  const [selectedPOS, setSelectedPOS] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [types, setTypes] = useState([]);
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(null);
  const [open, setOpen] = useState(false);
  const [openKyc, setOpenKyc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingWal, setLoadingWal] = useState(false);
  const [tierLevel, setTierLevel] = useState(null);
  const [pin, setPin] = useState({});
  const [openPin, setOpenPin] = useState(false);
  const [terminalRequestResp, setTerminalRequestResp] = useState();

  const { step, deliveryAddress, settlementAccount, accountToDebit, subTotal, terminalTypes } = state;
  const getUserWallets = useCallback(() => {
    apiCall({
      method: "get",
      url: `${walletDetails}`,
      success: (data) => {
        setWallet(data?.respBody);
      },
      setLoading: setLoadingWal,
    });
  }, []);

  const getUserKYCTierLevel = useCallback(() => {
    apiCall({
      method: "get",
      url: `${getUserKycLevel}${user.userId}`,
      success: (data) => {
        setTierLevel(data?.data?.tiers);
        if (data?.data?.tiers?.id <= 2) setOpenKyc(true);
      },
      setLoading,
      error: () => setOpenKyc(true),
    });
  }, []);
  const getTerminals = useCallback(() => {
    apiCall({
      method: "get",
      url: allterminalTypes,
      success: (data) => {
        setTypes(data);
      },
      defaultMeta: true,
      setLoading: setLoading,
    });
  }, []);

  useEffect(() => {
    getUserWallets();
    getTerminals();
    getUserKYCTierLevel();
  }, []);

  useEffect(() => {
    calculateTotal(currentType);
    setReset(false);
  }, [currentType]);

  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < selectedPOS.length; i++) {
      sum += selectedPOS[i].subtotal;
    }
    setTotal(sum);
  }, [selectedPOS]);

  useEffect(() => {
    if (method === "PART") {
      setAmount(partPayment);
    } else {
      if (method === "FULL") {
        setAmount(total?.toLocaleString());
      }
    }
    return method;
  }, [method, partPayment, total]);
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, []);
  useEffect(() => {
    const amt = total * (50 / 100);
    if (formatDonation(partPayment) > 0) {
      if (
        formatDonation(partPayment) < amt ||
        formatDonation(partPayment) > total ||
        formatDonation(partPayment) === total
      ) {
        (formatDonation(partPayment) > total ||
          formatDonation(partPayment) === total) &&
          setMethod("FULL");
        setPartPayment(amt);
        setReset(formatDonation(partPayment) < amt);
      }
    }

    return amt;
  }, [partPayment]);
  const calculateTotal = (cur) => {
    if (!isEmpty(cur)) {
      const pos = [...selectedPOS];
      const temp = pos.filter((item) => item.terminalName !== cur.terminalName);
      if (currentType.qty > 0) {
        const basket = [...temp, cur];
        setSelectedPOS(basket);
      } else {
        setSelectedPOS(temp);
      }
    }
  };
  const changeHandler = (e) => setPartPayment(e.target.value);
  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 1200),
    []
  );

  const onChangeStep = (page, value) => {
    setState((state) => ({
      ...state,
      step: page,
      buttonValue: value,
    }));
  };
  const addQuantity = (cur) => {
    if (cur.id === currentType.id) {
      if (currentType.qty < currentType.quantity) {
        setCurrentType({
          ...currentType,
          qty: currentType.qty + 1,
          subtotal: (currentType.qty + 1) * currentType.amount,
        });
      }
    } else {
      setCurrentType({ ...cur, qty: 1, subtotal: parseInt(cur.amount, 10) });
    }
  };
  const subtractQuantity = (cur) => {
    if (cur.id === currentType.id) {
      if (currentType.qty > 0) {
        setCurrentType({
          ...currentType,
          qty: currentType.qty - 1,
          subtotal: (currentType.qty - 1) * currentType.amount,
        });
      }
    } else {
      const item = selectedPOS.find((item) => item.id === cur.id);
      if (item && item.qty > 0) {
        setCurrentType({
          ...item,
          qty: item.qty - 1,
          subtotal: (item.qty - 1) * item.amount,
        });
      }
    }
  };

  const onAddItem = (data, action) => {
    const value = data ? data.terminalName : "";
    let itemRemove = 0;
    // debugger
    //Check if the terminal type already exists
    const check = terminalTypes.find((terminal, i) => {
      itemRemove = i;
      return terminal.terminalName === value;
    });
    // debugger;
    if (check) {
      terminalTypes.splice(itemRemove, 1);
      if (action === "add") {
        let newQuantity = check.quantity;
        let total = subTotal;
        newQuantity = newQuantity + 1;
        total = total + parseInt(data.amount);
        let { quantity, ...details } = check;
        details = Object.assign(
          {
            quantity: newQuantity,
          },
          details
        );

        terminalTypes.push(details);
        setState((state) => ({
          ...state,
          subTotal: total,
        }));
      } else if (action === "remove") {
        if (check.quantity > 1) {
          let newQuantity = check.quantity;
          let total = subTotal;
          newQuantity = newQuantity - 1;
          total = total - parseInt(data.amount);
          let { quantity, ...details } = check;
          details = Object.assign(
            {
              quantity: newQuantity,
            },
            details
          );
          terminalTypes.push(details);
          setState((state) => ({
            ...state,
            subTotal: total,
          }));
        } else {
          let total = subTotal;
          total = total - parseInt(data.amount);
          setState((state) => ({
            ...state,
            subTotal: total,
          }));
        }
      }
    } else {
      if (action === "add") {
        let newQuantity = data.quantity;
        newQuantity = newQuantity + 1;
        let { quantity, ...details } = data;
        details = Object.assign(
          {
            quantity: newQuantity,
          },
          details
        );

        if (subTotal === 0) {
          let total = parseInt(data.amount);
          setState((state) => ({
            ...state,
            subTotal: total,
          }));
        } else {
          let total = subTotal;
          total = total + parseInt(data.amount);

          setState((state) => ({
            ...state,
            subTotal: total,
          }));
        }

        terminalTypes.push(details);
      }
    }
  };

  const onSelectPayment = (value) => {
    setState((state) => ({
      ...state,
      fullOrPartPayment: value,
    }));
  };

  const makePayment = async () => {
    let data = {
      deliveryAddress,
      accountToDebit,
      settlementAccount,
      fullOrPartPayment: "FULL",
      partPaymentAmount: total,
      subTotal: total,
      terminalTypes: selectedPOS,
    };

    setState((s) => ({ ...s, loading: true }));
    try {
    await axios
      .post(requestTerminal, data)
      .then((res) => {
        console.log({ llllllllllll: res })
        if (res?.status == 200 && res.data.respCode === 0) {
          // setOpen(true);
          // setTerminalRequestResp(true);
          Swal.fire(res?.data?.respDesc, "Application for terminal is successful")
          toast.success("Application for terminal is successful", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          Swal.fire('Oops!', res?.data?.respBody);
          toast.error(res?.data?.respBody, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .finally(() => {
        setState((s) => ({ ...s, loading: false, total: 0 }));
        setOpen(false);
        setTerminalRequestResp(false);
        navigate("/terminal-requests");
      });
    } catch (error) {
      Swal.fire('Oops!', error?.response?.data?.respBody);
      toast.error(error?.response?.data?.respBody, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      // if (error?.response?.data?.message === 'Validation Errors') {
      //   Object.values(error?.response?.data?.data).map((item) =>
      //     swal('Oops!', item, 'error')
      //   );
      //   return {
      //     status: false,
      //     message: error?.response?.data.data[0],
      //   };
      // }
    }
  };
  const confirmPin = () => {
    return apiCall({
      url: `${validatePin}${formatDonation(pin)}`,
      setLoading,
      success: (data) => makePayment(),
      // error: ()=>setStep({back: 1, next: 2, key: 'confirm'}),
      method: "get",
    });
  };

  const successMsg = (
    <div className="fs-18">
      Hello, you have successfully paid for your wayaPOS device and it will be
      delivered to your provided address within 2 - 3 working days. You can also
      check the status of your request on your dashboard
    </div>
  );

  const temp = {
    select: <TerminalAddress />,
    merchantForm: <MerchantForm />,
    pay: <Payment setShowTopUp={setShowTopUp}  state={state} setState={setState} />,
  };

  return (
    <AddTerminalContext.Provider
      value={{
        state,
        setState,
        onChangeStep,
        makePayment,
        setOpenPin,
        onAddItem,
        onSelectPayment,
        subtotal,
        currentType,
        setCurrentType,
        currentQuantity,
        wallet,
        setWallet,
        total,
        amount,
        types,
        loading,
        selectedPOS,
        subtractQuantity,
        addQuantity,
        loadingWal,
      }}
    >
      <Layout title="Terminals">
        <Container fluid>
          {temp[step]}
          <Divider />
          <SubTotal />
          <ChangePinModal state={open}>
            {terminalRequestResp ? (
              <SuccessTemp
                titleDown={
                  <div className="fw-bold fs-20">
                    WayaPOS Purchase Successful
                  </div>
                }
                message={successMsg}
                okText={
                  <div className="text-center  ">
                    <button className="btn text-white bg-orange">
                      Ok, Go Home
                    </button>
                  </div>
                }
                close={() => {
                  setOpen(false);
                  navigate("/terminals");
                }}
              />
            ) : (
              <FailedTemp
                close={() => {
                  setOpen(false);
                  navigate("/terminals");
                }}
                message={`Kindly fund your account`}
              />
            )}
          </ChangePinModal>
          <ChangePinModal state={openPin}>
            <ConfirmTransfer
              handleChange={(e) => setPin(e.target.value)}
              cancel={() => setOpenPin(false)}
              loading={loading}
              confirm={confirmPin}
              formData={{ from: wallet?.accountNo, amount: total }}
            />
          </ChangePinModal>

          <ChangePinModal state={openKyc}>
            <ErrorTemp
              loading={false}
              close={() => {
                setOpenKyc(false);
                navigate("/terminals");
              }}
              retryCB={() =>
                tierLevel === null
                  ? getUserKYCTierLevel()
                  : navigate("/settings/?kyc", {
                      state: { enableTeamMember: false },
                    })
              }
              okText="Take me back"
              retryTxt={tierLevel === null ? "Try Again" : "Upgrade KYC"}
              titleTop="Unable To Request Terminal"
              titleDown={tierLevel === null ? "Failed Request" : "Upgrade KYC"}
              message={
                tierLevel === null
                  ? "We could not retrieve your Tier Level information"
                  : "Only merchants with KYC tier level higher than 2 can perform this request"
              }
            />
          </ChangePinModal>
          <TopUpModal showTopUp={showTopUp} setShowTopUp={setShowTopUp} />
        </Container>
        <div style={{ marginBottom: 100 }} />
      </Layout>
    </AddTerminalContext.Provider>
  );
};

export default AddTerminal;
