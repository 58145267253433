import _ from 'lodash';
import swal from 'sweetalert';
import crypto from 'crypto';
// import crypto from 'crypto-browserify'
// import {Buffer} from 'buffer'

import {
  followOrUnfollowPage,
  handleFollow,
  handleUnFollow,
  joinGroup,
  leaveAndDeleteGroup,
} from '../components/pages/Wayagram/container';
import {
  getDiscoverData,
  searchGroups,
  searchPages,
  searchTrendingTags,
  searchWayagramPeople,
} from '../store/calls';
import validateImage from './validateImage';
const iv = crypto.randomBytes(16);
const key = {
  type: 'Buffer',
  data: [
    51, 152, 235, 68, 51, 42, 62, 50, 40, 136, 55, 165, 108, 253, 6, 132, 33,
    161, 12, 35, 102, 209, 72, 212, 33, 225, 74, 14, 109, 149, 149, 227,
  ],
};
export const decrypt = (text) => {
  console.log('.>>>', text);
  const iv = Buffer.from(text.iv, 'hex');
  const encryptedText = Buffer.from(text.encryptedData, 'hex');
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

export function encrypt(text) {
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
  };
}

export function duplicateTransChecker(tranData) {
  const lastTranData = localStorage.getItem('transaction');
  const data = JSON.parse(lastTranData) ?? {};
  console.log({ data, tranData });
  const date1 = new Date(tranData.time);
  const date2 = new Date(data.time);
  const diffTime = Math.abs(date2 - date1) / 1000;
  console.log(diffTime / 1000);
  if (
    data?.description === tranData?.description &&
    data?.account === tranData?.account &&
    data?.amount === tranData?.amount &&
    diffTime < 60
  ) {
    return true;
  }
  return false;
}

export const forceUpdate = (todoUpdates) => todoUpdates((n) => n + 1);

export const isEmpty = (value) => {
  /* eslint-disable no-unused-expressions */
  value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
};

export const capitalize = (item) => _.capitalize(item);

export const capFirstWord = (str) => _.startCase(_.toLower(str));

export const checkAcess = (value) => {
  const regexFormat = /^[[0-9+-]+$/;
  console.log(regexFormat.test(value));
  return regexFormat.test(value);
};

export const findHashTag = (string) => {
  let str = string;
  const n = str?.search('#'); // find character, n = 6 in this case
  str = str?.slice(0, n) + str?.slice(n + 1); // remove char at index n
  return str;
};

export const sort = (data) => {
  return [...data].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );
};

export const handlePreview = (e, setImage, setPreview) => {
  const imageData = e.target.files[0];
  const validFormat = validateImage(imageData);
  if (validFormat.valid) {
    setImage(imageData);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(imageData);
  } else {
    swal('Oops!', `${validFormat.message}`, 'error');
  }
};

const convertMinutes = (num, type) => {
  if (type === 'hr') {
    return Math.floor(num * 60);
  }
  if (type === 'days') {
    return Math.floor(num * 1440); // 60*24
  }
  return 0;
};

export const convertTimeToMs = (days, hours, min) => {
  console.log(days, hours, min);
  const date = new Date(); // Todays date - the Date() constructor will default to the current date/time if no value is passed to it

  const addDays = convertMinutes(Number(days), 'days');
  const addHours = convertMinutes(Number(hours), 'hr');
  const addMinutes = Number(min);

  const totalMin = addDays + addHours + addMinutes;

  // Add minutes
  date.setTime(date.getTime() + totalMin * 60 * 1000); // Convert minutes to milliseconds

  console.log(date);
  return date;
};

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // message.error('You can only upload JPG/PNG file!');
    swal('Oops!', `You can only upload JPG/PNG file!`, 'error');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // message.error('Image must smaller than 2MB!');
    swal('Oops!', `Image must smaller than 2MB!`, 'error');
  }
  return isJpgOrPng && isLt2M;
}

export const handleSearchFriends = async (
  val,
  activeTab,
  id,
  loggedInUserId
) => {
  let resData;
  const userId = loggedInUserId || id;
  if (activeTab === 'Posts') {
    resData = await getDiscoverData(activeTab, 0, val);
  }
  if (activeTab === 'People') {
    resData = await searchWayagramPeople(val, userId);
  }
  if (activeTab === 'Groups') {
    resData = await searchGroups(val, 1, userId);
  }
  if (activeTab === 'Pages') {
    resData = await searchPages(val, 1, userId);
  }
  if (activeTab === 'Trending') {
    const removeHash = findHashTag(val);
    resData = await searchTrendingTags(removeHash, 'ASC');
  }
  return resData;
};

export const handleWayaSubmit = async (
  type,
  username,
  objectId,
  loggedInUser,
  active,
  searchValue,
  handleSearch,
  setList
) => {
  const { id, UserId: userId } = loggedInUser;
  let res;
  if (active === 'People') {
    if (type === 'Follow') {
      res = await handleFollow(userId, username);
    } else if (type === 'Unfollow') {
      res = await handleUnFollow(userId, username);
    }
  }
  if (active === 'Pages') {
    if (type === 'Follow' || type === 'Unfollow') {
      res = await followOrUnfollowPage(id, objectId);
    }
  }

  if (active === 'Groups') {
    if (type === 'Join') {
      res = await joinGroup(id, objectId);
    } else if (type === 'Leave') {
      res = await leaveAndDeleteGroup(id, objectId);
    }
  }

  if (res.status) {
    swal('Done', res.message, 'success').then(async () => {
      if (active === 'People') {
        const resData = await searchWayagramPeople(searchValue, id);
        setList(resData);
      } else {
        await handleSearch(searchValue, active, id);
      }
    });
  } else {
    swal('Oops!', res.message, 'error');
  }
};
