import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from "@mui/material";
import wayabank from "../../../assets/wayabank.svg";

export const ConfirmationDialog = ({ openDialog, setOpenDialog, onClose }) => {
  const handleCancel = () => {
    onClose();
    setOpenDialog(false);
  };
  return (
    <>
      <Dialog maxWidth="xs" open={openDialog} onClose={handleCancel}>
        <DialogTitle>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={wayabank} alt={"wayabank"} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: "center" }}>
            To perform this transaction, kindly visit
            <a
              target="_blank"
              href="https://www.wayabank.ng/login"
              rel="noreferrer"
            >
              {" www.wayabank.ng"}
            </a>
            or click the proceed button below
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ backgroundColor: "#f2f2f2", justifyContent: "space-evenly" }}
        >
          <Button onClick={onClose} sx={{ color: "#858585" }}>
            {"Cancel"}
          </Button>
          <Button
            sx={{ display: "flex", alignItems: "center", color: "#858585" }}
            onClick={() =>
              window.open(
                "https://www.wayabank.ng/login",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            <span>{"Proceed"}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
