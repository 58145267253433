import axios from "axios";
import { initiateTransfer, transferFund } from "../../plugins/urls";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Slide, toast } from "react-toastify";
import { getUser } from "../../utilities/objectOperations";
import "./styles.scss";

export const NewTopUp = ({ setShowTopUp }) => {
  const [TopUp, setTopUp] = useState({
    disabled: true,
    error: {},
  });
  const [loading, setLoading] = useState(false);
  const { email, firstName, lastName, phoneNumber } = getUser();

  const { error, amount, description, disabled } = TopUp;

  const handleClose = () => {
    setTopUp({ disabled: true, error: {} });
    setShowTopUp({ show: false });
  };

  useEffect(() => {
    if (+amount >= 100 && description?.length > 4) {
      setTopUp((dt) => ({ ...dt, disabled: false }));
    }
    /*  return () => {
      setLoading(false);
      setTopUp({ disabled: true, error: {} });
    }; */
  }, [amount, description]);

  const handleProceed = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${initiateTransfer}`, {
        amount,
        email,
        description,
        name: `${firstName} ${lastName}`,
        phoneNumber,
      })
      .then((res) => {
        const {
          status,
          data: { respCode, respDesc, respBody },
        } = res;
        if (status === 200 && respCode === 0) {
          const { authUrl, transId } = respBody;
          setShowTopUp((dt) => ({ ...dt, show: false }));
          window.open(`${authUrl}`);
        } else {
          toast.error(respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const { error } = TopUp;
    if (name === "amount") {
      const newErr = { ...error, amount: +value < 100 ? true : false };
      setTopUp((dt) => {
        return { ...dt, error: newErr, [name]: value };
      });
    }
    if (name === "description") {
      const newErr = {
        ...error,
        description: value.toString().length < 4 ? true : false,
      };
      setTopUp((dt) => {
        return { ...dt, error: newErr, [name]: value };
      });
    }
  };
  return (
    <div>
      <form className="withdrawal-input">
        <div>
          <label>{"Full Name"}</label>
          <input disabled value={`${firstName} ${lastName}`} />
        </div>
        <div>
          <label>{"Email"}</label>
          <input disabled value={`${email}`} />
        </div>
        <div>
          <label>{"Amount to Top up"}</label>
          <div className="amount-input">
            <span className="currency">{"NGN"}</span>
            <input
              type={"number"}
              min={0}
              name={"amount"}
              onChange={handleChange}
              value={amount}
            />
          </div>
          {error.amount && (
            <p className="text-red">
              {"Amount to top up cannot be less than 100"}
            </p>
          )}
        </div>
        <div>
          <label>{"Description"}</label>
          <input
            name={"description"}
            onChange={handleChange}
            value={description}
          />
          {error.description && (
            <p className="text-red">{"Description too short"}</p>
          )}
        </div>
        <div className="d-flex justify-content-end px-4 py-3">
          <button className="button" type={"button"} onClick={handleClose}>
            {"Cancel"}
          </button>

          <button
            className="button"
            disabled={disabled || error?.description || error?.amount}
            onClick={handleProceed}
          >
            {"Proceed"}
            {loading && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
