import Button from "@material-ui/core/Button";
import { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../utilities/objectOperations";
import { ReactComponent as Map } from "../../assets/icons/location.svg";
import { AddTerminalContext } from "../../pages/Terminals/AddTerminal";
import Divider from "../Divider/Divider";
import "./select.scss";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const Address = () => {
  const { otherDetails, posUserProfile, ...user } = getUser();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const {
    state: { deliveryAddress, organizationName },
    setState,
    state,
  } = useContext(AddTerminalContext);
  return (
    <div className="address mt-20">
      <h4 className="text-grey fs-20 fw-700">Delivery Address</h4>
      <div className="d-flex justify-content-between mt-20 mb-15">
        <div className="d-flex justify-content-start">
          <Map className="mt-02" />
          <div className="ml-30">
            <h4 className="text-darker fs-16 fw-700">{organizationName}</h4>
            <h4 className="text-darker fs-16 fw-700">{deliveryAddress}</h4>
            <h4 className="text-grey fs-16 fw-400">{user.phoneNumber}</h4>
          </div>
        </div>

        <div>
          <Button
            className="change-button"
            onClick={() => {
              // navigate("/settings?update-profile")
              setShowModal(true);
            }}
          >
            Change
          </Button>
        </div>
      </div>

      <Divider />

      <Row className="mt-20">
        <Col lg={4}>
          <h4 className="text-grey fs-16 fw-400">
            Once you click on the continue button below, you will proceed to
            make full payment for your POS order. <br />
            {/*<br /> Part payment will require a minimum of 50% upfront and the 50% is to be paid over the 30 days.*/}
          </h4>
        </Col>
      </Row>
      <Modal isOpen={showModal} centered>
        <ModalHeader className="justify-content-center border-0">
          {/* <div className="text-center">
            <img src={securePinImg} alt="pin" style={{ height: '163px' }} />
          </div> */}
          <div>
            <h1 className="modal-header-data mb-0">Change Address</h1>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="center" style={{ textAlign: 'center', width: '90%'}}>
            <textarea
              className="border"
              value={deliveryAddress}
              onChange={(e) => setState({ ...state, deliveryAddress: e.target.value })} 
              placeholder="Address"
            />
          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={() => setShowModal(false)}
            >Change</button>
            <button
             onClick={() => setShowModal(false)}
             className="btn btn-danger mx-3">
              Cancel
            </button>

          </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Address;
