import React, { createContext, useState, useEffect } from 'react';
import { customBaseUrl, httpGet, httpPost } from '../../action/http';
import { apiCall2 } from '../../pos/utilities/apis2';
import { getPosMerchantProfile, getUserProfile } from '../../pos/plugins/urls';
import axios from 'axios';

// export const userId = localStorage.getItem('userId');

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const { children } = props;
  const [profile, setProfile] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [reload, setReload] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [isBvnLinked, setIsBvnLinked] = useState(true);
  const [verifiedEmailReload, setVerifiedEmailReload] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);
  const [emailVerified, setEmailVerified] = useState(true);
  const [phoneVerified, setPhoneVerified] = useState(true);
  const [verifyDetails, setVerifyDetails] = useState({});
  const [subscriptions, setSubscription] = useState({});
  const [kycData, setKYCData] = useState([]);

  const getProfileState = async (userId, profileId) => {
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/profile/${userId}/${profileId}`
    );
    const resp = await axios.get(`${getPosMerchantProfile}`,
      {
        headers: {
          Authorization: `${localStorage.token}`,
          'CLIENT-ID': 'WAYAPOS',
          'CLIENT-TYPE': 'CORPORATE',
        },
      }
    );
    const uD = {
      user: res?.data,
      posUserProfile: resp?.data?.respBody || { merchantId: "" },
    };
    if (res?.status && resp.data) {
      // setProfile(res.data);
      setProfile(res?.data);
      localStorage.setItem(
        "userDetails",
        JSON.stringify(uD)
      );
    }
  };
  const fetchSubscription = async (userId) => {
    const res = await httpPost(
      `/api/v1/profile/fetchSubscriptions/${userId}`,
      {},
      customBaseUrl.authUrl
    );
    if (res?.status) {
      // setProfile(res.data);
      setSubscription(res?.data);
    }
  };
  const getKyc = async (userId) => {
    const res = await httpPost(
      `/api/v1/kyc/requirementData/${userId}`,
      {},
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      if (res?.data) {
        const email1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'EMAIL'
        )[0];
        const phone1 = res?.data?.tier1?.filter(
          (e) => e?.requirementType === 'PHONE_NUMBER'
        )[0];
        const bvn1 = res?.data?.tier2?.filter(
          (e) => e?.requirementType === 'BVN'
        )[0];
        setEmailVerified(email1?.status === 'APPROVED' ?? false);
        setPhoneVerified(phone1?.status === 'APPROVED' ?? false);
        setIsBvnLinked(
          bvn1?.status === 'APPROVED' || bvn1?.status === 'UNDER_REVIEW'
            ? true
            : false
        );
        setKYCData(res?.data);
      } else {
        console.log(res);
      }
    }
  };
  const getUserState = async (userId) => {
    const res = await httpGet(`${customBaseUrl.authUrl}/api/v1/user/${userId}`);
    if (res?.status) {
      console.log(res?.data?.phoneVerified);
      //  setEmailVerified(res?.data?.emailVerified);
      //  setPhoneVerified(res?.data?.phoneVerified);
      setUserDetails(res?.data);
      console.log('Na the user details be this', res?.data);
    }
  };
  const getVerifyUser = async () => {
    const res = await httpPost(
      `/api/v1/auth/validate-user`,
      {},
      customBaseUrl.authUrl
    );
    if (res?.status) {
      setVerifyDetails(res?.data);
      console.log('Na the user details be this', JSON.stringify(res?.data));
    }
  };

  const getReferralCode = async (userId) => {
    const res = await httpGet(`
      ${customBaseUrl.authUrl}/api/v1/referral/getReferralCode/${userId}`);
    console.log('referalll', res);
    if (res?.status) {
      setReferralCode(res?.data?.referralCode);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    getUserState(userId);
    getVerifyUser();
  }, [reloadUser]);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    const profileId = localStorage.getItem('profileId');
    getProfileState(userId, profileId);
    fetchSubscription(userId);
    getReferralCode(userId);
    getKyc(userId);
  }, [reload]);

  return (
    <ProfileContext.Provider
      value={{
        setProfile,
        profile,
        reload,
        setReload,
        referralCode,
        isBvnLinked,
        setIsBvnLinked,
        setVerifiedEmailReload,
        verifiedEmailReload,
        userDetails,
        verifyDetails,
        setReloadUser,
        reloadUser,
        setPhoneVerified,
        phoneVerified,
        setEmailVerified,
        emailVerified,
        subscriptions,
        fetchSubscription,
        kycData,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
