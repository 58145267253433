import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, Routes, useHistory, useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import AccountStatement from './AccountStatement';
import { ProfileContext } from '../../../store/context/ProfileContext';
import './setting.scss';
import vector from '../../../assets/image/Vector.svg';
import UploadTier from './UploadTier';
import { customBaseUrl, httpDelete, httpGet } from '../../../action/http';
// import { WalletContext } from '../../../store/context/WalletContext';
import ConfirmModal from '../../shared/Modals/Confirm/confirmationModal';
import PinModal from '../../shared/Modals/PinModal';
import { hideLoader, showLoader } from '../../../utils/loader';
import Loader from '../../shared/Loader';
import InAppLogin from '../../shared/Modals/InAppLogin';
// import ReferalView from './ReferalView';
import Table from '../../shared/Table';
import Back from '../../../assets/image/back-caret.svg';
import ComingSoon from '../../shared/Modals/comingSoon/CommingSoonModal';
import { PaymentContext } from '../../../store/context/PaymentContext';
import { WalletContext } from '../../../store/context/WalletContext';
import UpdateLimit from './updateLimit';
import LimitOtp from './updatelimitOtp';
import AccountRoles from './accountRoles';

export default function EditProfile(props) {
  const { userDetails, referralCode, profile } = useContext(ProfileContext);
  const { walletList: bankAccountList } = useContext(WalletContext);

  // const { defaultWallet } = useContext(WalletContext);
  const [activeTab, setActiveTab] = useState('profile');
  const [kycStatus, setKycStatus] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPageLoader, setShowPageLoader] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [resetPinIsCompleted, setResetPinIsCompleted] = useState(false);
  const [mode] = useState('');
  const [referalData, setReferalData] = useState({});
  const [referalAmount, setReferalAmount] = useState('');
  const [coming, setComing] = useState(false);
  const { virtualAccount } = useContext(PaymentContext);
  const [tiers, setTiers] = useState('');
  const [level, setLevel] = useState(1);
  const [cacLevel, setCacLevel] = useState(1);
  const [showUpdateLimit, setShowUpdateLimit] = useState(false);
  const [showUpdateLimitOTP, setShowUpdateLimitOTP] = useState(false);
  // fegwe
  console.log(activeTab);
  const navigate = useNavigate();
  const {
    setShowProfileModal,
    // setShowPrivacyModal,
    setShowChangePassword,
    options,
    setShowResendOtp,
    setModalAction,
    setShowInviteModal,
  } = props;

  useEffect(() => {
    // navigate('/settings/account');
  }, []);
  const getKycStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/fetchCurrentLevel/${userDetails.userId}`
    );
    if (res?.status) {
      setTiers(res?.data?.kycTier);
      setKycStatus(res.data);
      setLevel(res?.data?.kycTier?.orderLevel);
      console.log('tier', kycStatus, tiers);
    }
    return [];
  };
  const getCACKycStatus = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/kyc/cac/fetchBusinessKycCurrentLevel/${userDetails.userId}/${localStorage.profileId}`
    );
    if (res?.status) {
      // setLevel(res?.data);
      // setTiers(res?.data?.kycTier);
      // setKycStatus(res.data);
      setCacLevel(res?.data?.kycTier?.orderLevel);
      // console.log('tier', kycStatus, tiers);
    }
    return [];
  };

  const getReferals = async () => {
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/admin/get-users-that-have-been-referred/${referralCode}`
    );
    if (res?.status) {
      setReferalData(res?.data);
    }
    return [];
  };
  const getReferalL = async () => {
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/fetchReferralCustomers?pageNo=1&pageSize=5`
    );
    if (res?.status) {
      setReferalAmount(res?.data?.totalElements);
    }
    return [];
  };

  const getTotalReferalAmount = async () => {
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/admin/get-users-total-referral-earnings/${userDetails.userId}`
    );
    if (res && res.status) {
      //  setReferalAmount(res.data);
    }
    return [];
  };

  const handleDeleteUser = async () => {
    // const postData = {
    //   deleteType: 'DELETE',
    //   userId: userDetails.userId,
    // };
    showLoader();
    const res = await httpDelete(
      `/api/v1/user/delete/${userDetails.userId}`,
      {},
      customBaseUrl.authUrl
    );
    if (res.status) {
      hideLoader();
      setOtp('');
      setShowPin(false);
      swal('Done', res.message, 'success');
      localStorage.clear();
      window.location.href = '/wallet';
    } else {
      hideLoader();
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleVerifyPin = async () => {
    setLoading(true);
    if (!otp || otp === '') {
      setLoading(false);
      swal('Oops!', 'PIN is required', 'error');
      return;
    }
    const res = await httpGet(
      `${customBaseUrl.authUrl}/api/v1/pin/validate-pin/${otp}`
    );
    // console.log(res);
    if (res.status) {
      setLoading(false);

      handleDeleteUser();
      setOtp('');
      setShowPin(false);
    } else {
      setLoading(false);
      swal('Oops!', res.message || 'Something went wrong', 'error');
    }
  };

  const handleClick = (title) => {
    if (title === options[0].title) {
      setShowProfileModal(true);
    } else if (title === options[1].title) {
      // setShowPrivacyModal(true);
      setComing(true);
    } else if (title === options[2].title) {
      setShowChangePassword(true);
    } else if (title === options[3].title) {
      setModalAction('resetPin');
      setShowLogin(true);
      // } else if (title === options[4].title) {
      //   setDeleteModal(true);
      //   setMode('delete');
    } else if (title === options[4].title) {
      // setComing(true);
      setActiveTab('accountStatement');
      navigate('/settings/account/statement');
    } else if (title === options[6].title) {
      // setComing(true);
      setActiveTab('accountroles');
      navigate('/settings/account/roles');
    }
  };

  useEffect(() => {
    if (resetPinIsCompleted) setShowResendOtp(true);
    return () => setResetPinIsCompleted(false);
  }, [resetPinIsCompleted]);

  useEffect(() => {
    if (referralCode) {
      getReferals();
      getReferalL();
    }
    return () => {
      setReferalData([]);
    };
  }, [referralCode]);
  useEffect(() => {
    getTotalReferalAmount();
    if (!userDetails?.userId) {
      setShowPageLoader(true);
    } else {
      getKycStatus();
      getCACKycStatus();
      setShowPageLoader(false);
    }
  }, [userDetails?.userId]);

  if (showPageLoader) {
    return <Loader />;
  }
  const AccountSettings = () => (
    <div>
      <div className="text-center my-5 head">Account Settings</div>
      <div className="profile-detail mt-3">
        <div className="pd-1">
          <div className="avatar-case profile-avatar-div">
            {profile.profileImage ? (
              <img
                className="img"
                src={profile?.profileImage}
                alt="avatar"
              />
            ) : (
              <span className="avatar-text">
                {`${userDetails?.firstName?.slice(
                  0,
                  1
                )}${userDetails?.lastName?.slice(0, 1)}` || '_'}
              </span>
            )}
          </div>
          <p className="pd-1-t">
            {`${userDetails?.firstName} ${userDetails?.lastName}`}
          </p>
          <p className="pd-1-b">
            {/* Acct:
            <span className="text-black-50">
              {virtualAccount?.accountNumber}
                </span> */}
          </p>
        </div>

        <div className="pd-2">
          <button
            style={{ width: 150 }}
            type="button"
            onClick={() => {
              setShowUpdateLimitOTP(true);
              // navigate('/settings/account/refer');
            }}
          >
            Update Limit
          </button>
          <div className="pd-2-t">
            <img src={vector} alt="" />
            <p>
              T{profile?.cacBusiness ? cacLevel : level}{' '}
              <span
                onClick={() => {
                  setActiveTab('uploadTier');
                  // navigate('/settings/account/upgrade-kyc');
                }}
                className="add-cursor"
              >
                Upgrade
              </span>{' '}
              your account to <br /> do larger transactions
            </p>
          </div>

          <div className="pd-2-b">
            <div className="pd-2-b-1">
              <p className="pd2b1-1"> Invite & Earn</p>

              <button
                type="button"
                onClick={() => {
                  setShowInviteModal(true);
                  setActiveTab('ReferView');
                  // navigate('/settings/share-invite');
                  // navigate('/settings/invite-share');
                }}
              >
                View Refferal Code
              </button>
            </div>
            <div className="pd-2-b-2">
              <span>Total Referrals:</span>

              <span className="fw-bold text-black">{`${
                referalAmount || 0.0
              }`}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="account-options mx-auto my-5">
        {options &&
          options.map(({ id, title, description }) => (
            <div
              key={id}
              className="options d-flex align-items-center"
              onClick={() => handleClick(title)}
            >
              <div className="">
                <div className="mb-0">{title}</div>
                <div className="text-muted mb-0 small-text">
                  {description}
                </div>
              </div>

              <div className="">
                {title === 'Hide Balance' ? (
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                    />
                  </div>
                ) : (
                  '>'
                )}
              </div>
            </div>
          ))}
      </div>
      {/* <div
        className="log-out add-cursor"
        onClick={() => {
          setDeleteModal(true);
          setMode('delete');
        }}
      >
        Delete my Account
      </div> */}
    </div>
  );

  const AccountStatement = () => (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div
          className="d-flex"
          onClick={() => {
            setActiveTab('profile');
            navigate('/settings');
          }}
        >
          <img src={Back} alt="" className="me-2" />
          <div>Back</div>
        </div>
      </div>
      <AccountStatement
        accountNo={virtualAccount?.accountNumber}
        bankList={bankAccountList}
      />
    </>
  );

  const AccountRole = () => (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div
          className="d-flex"
          onClick={() => {
            setActiveTab('profile');
            navigate('/settings/account');
          }}
        >
          <img src={Back} alt="" className="me-2" />
          <div>Back</div>
        </div>
      </div>
      <AccountRoles
        accountNo={virtualAccount?.accountNumber}
        bankList={bankAccountList}
      />
    </>
  );

  const AccountSignup = () => (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div
          className="d-flex"
          onClick={() => {
            setActiveTab('ReferView');
            navigate('/settings/account');
          }}
        >
          <img src={Back} alt="" className="me-2" />
          <div>Back</div>
        </div>
        <div>Total Signup: {referalData?.referredUsers?.length}</div>
      </div>

      <Table
        columns={[
          { title: 'USER NAME', field: 'fullName' },
          { title: 'SIGN-UP DATE', field: 'dateJoined' },
          { title: 'PHONE', field: 'phone' },
          {
            title: 'TRANSACTION PERFORMED',
            field: 'numberOfTransactions',
          },
          { title: 'AMOUNT EARNED', field: 'amountEarned' },
          { title: 'EMAIL', field: 'email' },
        ]}
        data={referalData?.referredUsers}
      />
    </>
  );
  return (
    <div className="setting-inner-item">
      <div className="card p-4 mx-auto mt-5" id="accountSettings">
        {activeTab === 'uploadTier' ? (
          <UploadTier kycStatus={kycStatus} />
        ) : (
          <AccountSettings />
        )}
        {/* <BrowserRouter>
        <Routes>
        <Route
          exact
          path="/settings/account"
          element={<AccountSettings />}
        />
        <Route
          path="/settings/account/upgrade-kyc"
          element={() => <UploadTier kycStatus={kycStatus} />}
        />

        <Route
          path="/settings/account/statement"
          element={<AccountStatement />}
        />
        <Route
          path="/settings/account/roles"
          element={<AccountRole /> }
        />
        <Route
          path="/settings/account/referal-table"
          element={<AccountSignup />}
        />
        </Routes>
        </BrowserRouter> */}
      </div>
      {deleteModal ? (
        <ConfirmModal
          showModal={deleteModal}
          hideModal={setDeleteModal}
          title="Delete Profile"
          description="Please Confirm Profile Delete"
          handleSubmit={() => {
            setShowLogin(true);
          }}
        />
      ) : (
        ''
      )}

      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          loading={loading}
          separator=""
          buttonLabel="Continue"
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
          handleSubmit={handleVerifyPin}
          setShowResendOtp={setShowResendOtp}
          setModalAction={setModalAction}
        />
      ) : (
        ''
      )}
      {showLogin ? (
        <InAppLogin
          showModal={showLogin}
          hideModal={setShowLogin}
          email={userDetails?.email}
          handleSubmit={() => {
            if (mode === 'delete') {
              handleDeleteUser();
            } else {
              setResetPinIsCompleted(true);
            }
          }}
        />
      ) : (
        ''
      )}
      {showUpdateLimit ? (
        <UpdateLimit
          showModal={showUpdateLimit}
          hideModal={setShowUpdateLimit}
          mode="profile"
        />
      ) : (
        ''
      )}
      {showUpdateLimitOTP ? (
        <LimitOtp
          showModal={showUpdateLimitOTP}
          hideModal={setShowUpdateLimitOTP}
          updateLimit={setShowUpdateLimit}
          mode="profile"
        />
      ) : (
        ''
      )}
      <ComingSoon showModal={coming} hideModal={setComing} />
    </div>
  );
}
