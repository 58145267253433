import React, { useState } from 'react';
// import React, { useState, useContext } from 'react';
// import { connect, useSelector } from 'react-redux'
import swal from 'sweetalert';
import './index.scss';
// import placeholder from './im1.jpg';
import Layout from '../../shared/Layout';
import { hideLoader, showLoader } from '../../../utils/loader';
// import { WayagramContext } from '../../../store/context/WayagramContext';

function InterestPage(props) {
  const { handlePost, history } = props;
  // const User = useSelector(state => state.User);
  const { from } = props;
  const [interests] = useState([]);
  const [selectedInterest, setSelectedInterest] = useState([]);

  // const { wayagramProfile } = useContext(WayagramContext);

  // useEffect(() => {
  //   if (!reload) {
  //     setReload(true);
  //   }
  //   console.log({ reload, wayagramProfile });
  // }, [])
  // const {
  //   setWayagramProfile,
  //   reload,
  //   setReload,
  // } = useContext(WayagramContext);

  // useEffect(() => {
  //   getMyInterest()
  //   getInterests()
  //   return () => {}
  // }, [User._id])

  // const getInterests = async () => {
  //   const res = await props.handleGet(`/api/wayagram/interests`)
  //   if(res.status === 200){
  //     setInterests(res.data)
  //   }
  // }

  // const getMyInterest = async () => {
  //   if(props.from !== 'gram') showLoader()
  //   const res = await props.handleGet(`/api/wayagram/user/interests`)
  //   if(res.status === 200){
  //     setSelectedInterest([...selectedInterest, ...res.data.map(item => item._id)])
  //   }
  //   hideLoader()
  // }

  const handleSubmit = async () => {
    showLoader();
    const interestLenght = selectedInterest?.length;
    if (!interestLenght) {
      swal('Oops!', 'No selected interest', 'error');
      hideLoader();
      return;
    }
    const data = {
      interestedIdx: selectedInterest,
    };

    const res = await handlePost(`/api/users/interests/save`, data);
    const resStatus = res.status;
    if (resStatus === 200) {
      swal('Done', res.message || 'Successfully saved', 'success');
    }
    hideLoader();
  };

  const handleSelectInterest = (id) => {
    if (selectedInterest.includes(id)) {
      setSelectedInterest([...selectedInterest?.filter((item) => item !== id)]);
    } else {
      setSelectedInterest([...selectedInterest, id]);
    }
  };

  return (
    <Layout route={props} currentPage="Profile">
      <div
        className="interest-container fix-height"
        style={{
          background: '#f1f5f6',
          marginTop: 0,
          width: '100%',
          padding: '15px 0px',
        }}
      >
        <div className="intreasts-section">
          {interests.length
            ? interests.map((item, index) => (
                <div
                  className="interest-each add-pointer"
                  key={item.id}
                  style={{
                    // backgroundImage: item.image === '' ? `url(${placeholder})` : `url(${item.image})`,
                    // backgroundImage: item.image === '' ? `url(${placeholder})` : `url(${item.image})`,
                    gridArea: `grid${index + 1}`,
                  }}
                  onClick={() => handleSelectInterest(item.id)}
                >
                  <span
                    className={
                      selectedInterest?.includes(item.id)
                        ? 'interest-each-active'
                        : ''
                    }
                  >
                    {item.name}
                  </span>
                  <img
                    className="interest-markup"
                    style={
                      !selectedInterest?.includes(item.id)
                        ? { display: 'none' }
                        : {}
                    }
                    src="/assets/images/markup.svg"
                    alt="mark"
                  />
                </div>
              ))
            : ''}
        </div>

        <div className="interest-btn-grp">
          <button className="btn-2" onClick={handleSubmit} type="button">
            Submit
          </button>
          {from === 'gram' ? (
            <span
              className="add-pointer"
              onClick={() => navigate('/wayagram')}
            >
              Choose later
            </span>
          ) : (
            <span
              className="add-pointer"
              onClick={() => navigate('/wayagram')}
            >
              Choose later
            </span>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default InterestPage;
