import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import excapeRegExp from 'escape-string-regexp';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Progress } from 'antd';
// import bgRectangle from '../../../assets/image/Rectangle-1430.svg';
import searchIcon from '../../../assets/image/mdi_search.svg';
import airbnbLogo from '../../../assets/image/Airbnb.svg';
import youtubeIcon from '../../../assets/image/YouTubeIcon.svg';
import uberLogo from '../../../assets/image/UberLogo.svg';
import barcodeIcon from '../../../assets/image/s-barcode.png';
import questionIcon from '../../../assets/image/question.png';
import leftIcon from '../../../assets/image/leftIcon.png';
import rightIcon from '../../../assets/image/rightIcon.png';
import intersect from '../../../assets/image/intersect.png';
import {
  CardDetails,
  FundButton,
  IntersectLine,
  arrowStyles,
  rightArrowStyles,
} from './style';
import truncateWithEllipses from '../../../utils/truncate';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { WalletContext } from '../../../store/context/WalletContext';
import HistoryTab from './HistoryTab';
import {
  AirtimeIcon,
  BulbIcon,
  CableTvIcon,
  DataIcon,
} from '../../shared/Icons';
import { changeWallet } from '../../../store/redux-manager/actions';
import { customBaseUrl, httpGet } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { Cancel } from '@material-ui/icons';
import moneyFomatter from '../../../utils/moneyFormatter';
function mapDispatchToProps(dispatch) {
  return {
    changeWallets: (wall) => dispatch(changeWallet(wall)),
  };
}
const mapStateToProps = (state) => {
  return { currentWallet: state.wallet };
};
export const historyDatas = [
  {
    text: 'Maitama, Abuja',
    date: 'Yesterday',
    time: '11:22 AM',
    amount: '-N5000',
    icon: airbnbLogo,
  },
  {
    text: 'Maitama, Abuja',
    date: 'Nov 26th, 2020',
    time: '10:03 PM',
    amount: '+N5000',
    icon: youtubeIcon,
  },
  {
    text: 'Wuse Trip',
    date: 'Nov 26th, 2020',
    time: '02:00 AM',
    amount: '-N2000',
    icon: uberLogo,
  },
];

const ConnectedMainContent = (props) => {
  const {
    setShowFund,
    setShowQrCodeModal,
    showAlert,
    setShowAirtimeModal,
    setShowDataModal,
    setShowUtilityBillsModal,
    setShowCableModal,
    changeWallets,
  } = props;
  const { profile } = useContext(ProfileContext);
  const { walletList, transactionHistory, setDefaultWallet, debitLimit } =
    useContext(WalletContext);
  const [walletNo, setWalletNo] = useState(0);
  const [searchHist, setSearchHis] = useState([]);
  const [search, setSearch] = useState(false);
  // const [filteredDate, setFilteredDate] = useState('');
  const navigate = useNavigate();
  const getConfigurableProps = () => ({
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    // infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: true,
    autoPlay: false,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: true,
    // thumbWidth: number("thumbWidth", 100, {}, valuesGroupId),
    // selectedItem: number("selectedItem", 0, {}, valuesGroupId),
    interval: 8000,
    transitionTime: 290,
    width: '100%',
    // swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
  });
  const [searchTranId, setSearchTranId] = useState('');
  const [historyData, setHistoryData] = useState([
    {
      text: 'Maitama, Abuja',
      date: 'Yesterday',
      time: '11:22 AM',
      amount: '-N5000',
      icon: airbnbLogo,
    },
    {
      text: 'Maitama, Abuja',
      date: 'Nov 26th, 2020',
      time: '10:03 PM',
      amount: '+N5000',
      icon: youtubeIcon,
    },
    {
      text: 'Wuse Trip',
      date: 'Nov 26th, 2020',
      time: '02:00 AM',
      amount: '-N2000',
      icon: uberLogo,
    },
  ]);

  const handleSearchTran = async (val) => {
    val.preventDefault();
    /*  const match = new RegExp(excapeRegExp(val), 'i');
    const found = historyData.filter((item) =>
      match.test(item.text.toLowerCase())
    );
    setHistoryData(found); */
    if (searchTranId.length < 1) return;
    setSearch(true);
    showLoader();
    const res = await httpGet(`
      ${customBaseUrl.walletUrl}/api/v1/wallet/fetchByReferenceNumber/${searchTranId}`);

    if (res?.status) {
      hideLoader();
      console.log('serD', res?.data);
      setSearchHis(res?.data);
    } else {
      hideLoader();
      console.log('serE', res);
      setSearchHis([]);
    }
  };
  // console.log('walletInfo', walletList);

  const handlePrevious = () => {
    if (!walletNo) {
      return;
    }
    setWalletNo(walletNo - 1);
    setDefaultWallet(walletList[walletNo - 1]);
  };

  const handleNext = () => {
    // console.log(walletList[walletNo + 1]);
    if (walletNo === walletList?.length - 1) {
      return;
    }
    setWalletNo(walletNo + 1);
    setDefaultWallet(walletList[walletNo + 1]);
  };

  // const updateCurrentSlide = (index) => {
  //   if (walletNo !== index) {
  //     setWalletNo(index);
  //     setDefaultWallet(walletList[index]);
  //   }
  // };
  // useEffect(() => {
  //   if (walletList.length > 0) {
  //     updateCurrentSlide(0);
  //   }
  // }, [walletList]);
  const updateCurrentSlide = (index) => {
    changeWallets(walletList[index]);
    if (walletNo !== index) {
      setWalletNo(index);
      setDefaultWallet(walletList[index]);
    }
  };

  useEffect(() => {
    if (walletList?.length > 0) {
      updateCurrentSlide(walletNo ?? 0);
    }
  }, [walletList]);
  useEffect(() => {
    if (searchTranId.length < 1) {
      setSearch(false);
      setSearchHis([]);
    }
  }, [searchTranId]);
  return (
    <>
      <div className="mid-bottom-content">
        <div
          className={
            showAlert
              ? 'wayapay-wallet mt-2 border-0'
              : 'wayapay-wallet border-0'
          }
        >
          <Carousel
            {...getConfigurableProps()}
            selectedItem={walletNo}
            onChange={updateCurrentSlide}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...arrowStyles, left: '-22px' }}
                >
                  <img src={leftIcon} alt="arrow" onClick={handlePrevious} />
                </button>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <button
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{ ...rightArrowStyles, right: '-22px' }}
                >
                  <img src={rightIcon} alt="arrow" onClick={handleNext} />
                </button>
              )
            }
          >
            {walletList?.length ? (
              walletList.map((item, i) => (
                <div className="each-item" key={item.id}>
                  <FundButton onClick={() => setShowFund(true)}>
                    Fund Account
                  </FundButton>
                  <div className="mt-5" style={{ marginRight: 30 }}>
                    <h4 className="text-light text-end">
                      {item?.accountNo}
                      {''}
                    </h4>
                  </div>
                  <CardDetails>
                    <div
                    className="text-start"
                      onClick={() => {
                        setShowQrCodeModal(true);
                      }}
                    >
                      <img src={barcodeIcon} alt="barcode" />
                      <span className="ms-2 text-primary">
                        {item?.acct_name}
                      </span>
                      <span className="ms-2 text-primary">
                        {item.description}
                      </span>
                    </div>
                    <div className="mt-2 text-start">
                      <span className="me-2 text-light">
                        Available balance {i + 1}/{walletList.length}
                      </span>
                      <img src={questionIcon} alt="barcode" />
                    </div>
                    <h5 className="text-light text-start">
                      NGN {Number(item?.clr_bal_amt).toFixed(2)}
                    </h5>
                    <div className="mt-1 text-start">
                      <span className="me-2 text-light">Total balance</span>
                      <img src={questionIcon} alt="barcode" />
                    </div>
                    <h5 className="text-light text-start">
                      NGN{' '}
                      {(
                        Number(item?.un_clr_bal_amt) + Number(item?.blockAmount)
                      ).toFixed(2)}
                    </h5>
                  </CardDetails>
                  <IntersectLine>
                    <img src={intersect} alt="arrow" />
                  </IntersectLine>
                </div>
              ))
            ) : (
              <div>
                <Skeleton height={150} width={550} />
              </div>
              // <div className="each-item" key={Math.random()}>
              //   <FundButton onClick={() => setShowFund(true)}>
              //     Fund Wallet
              //   </FundButton>
              //   <CardDetails>
              //     <div
              //       onClick={() => {
              //         setShowQrCodeModal(true);
              //       }}
              //     >
              //       <img src={barcodeIcon} alt="barcode" />
              //       <span className="ms-2 text-primary">
              //         Hello {truncateWithEllipses(profile?.firstName, 10)}
              //       </span>
              //     </div>
              //     <div className="mt-3">
              //       <span className="me-2 text-light">
              //         Wallet balance {walletNo + 1}/{walletList.length}
              //       </span>
              //       <img src={questionIcon} alt="barcode" />
              //     </div>
              //     <h4 className="text-light text-start">NGN {0}</h4>
              //   </CardDetails>
              //   <IntersectLine>
              //     <img src={intersect} alt="arrow" />
              //   </IntersectLine>
              // </div>
            )}
          </Carousel>
          <div className="col-10 text-center center mx-auto justify-content-center my-2">
            your daily limit: NGN {moneyFomatter(debitLimit?.debitLimit ?? 0)}
            <Progress
              percent={
                (debitLimit?.totalTransactionToday / debitLimit?.debitLimit) *
                100
              }
              trailColor="#FAE7D8"
              status="exception"
              strokeColor="#ff6700"
              showInfo={false}
            />
            <div className="d-flex justify-content-between">
              <span className="row d-flex align-items-center">
                <div style={{ width: 15, height: 15, background: '#ff6700' }} />
                NGN{moneyFomatter(debitLimit?.totalTransactionToday ?? 0)} Used
              </span>
              <span className="row d-flex align-items-center">
                <div style={{ width: 15, height: 15, background: '#FAE7D8' }} />
                NGN
                {moneyFomatter(
                  debitLimit?.debitLimit - debitLimit?.totalTransactionToday ??
                    0
                )}{' '}
                Remaining
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-sm-flex mt-5 d-md-none">
        <div className="row justify-content-center">
          <div
            className="col-5 d-flex justify-content-center mb-3 p-3 items-right-list-item add-cursor"
            onClick={() => setShowUtilityBillsModal(true)}
          >
            <div className="me-2">
              <BulbIcon />
            </div>
            <div>Utility Bills</div>
          </div>
          <div
            className="col-5 d-flex justify-content-center ms-3 mb-3 p-3 items-right-list-item add-cursor"
            onClick={() => setShowAirtimeModal(true)}
          >
            <div className="me-2">
              <AirtimeIcon />
            </div>
            <div>Airtime</div>
          </div>
          <div
            className="col-5 d-flex justify-content-center p-3 items-right-list-item add-cursor"
            onClick={() => setShowDataModal(true)}
          >
            <div className="me-2">
              <DataIcon />
            </div>
            <div className="me-5">Data</div>
          </div>
          <div
            className="col-5 d-flex justify-content-center ms-3 p-3 items-right-list-item add-cursor"
            onClick={() => setShowCableModal(true)}
          >
            <div className="me-2">
              <CableTvIcon />
            </div>
            <div>Cabletv</div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSearchTran}>
        <div className="search-filter mt-5 mx-auto">
          <div className="trans-btn add-cursor">
            <p type="button" onClick={() => navigate('/transactions')}>
              Transaction History
            </p>
          </div>
          <input
            type="text"
            value={searchTranId}
            placeholder="Search by reference id"
            onChange={(e) => setSearchTranId(e.target.value)}
          />
          <div className="search-box">
            {!search ? (
              <img src={searchIcon} alt="" onClick={handleSearchTran} />
            ) : (
              <Cancel
                onClick={() => {
                  setSearchTranId('');
                  setSearch(false);
                }}
              />
            )}
          </div>
        </div>
      </form>
      <HistoryTab
        noBorder="true"
        historyData={search ? searchHist : transactionHistory}
      />
    </>
  );
};

ConnectedMainContent.propTypes = {
  setShowFund: PropTypes.func.isRequired,
};

const MainContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedMainContent);

MainContent.propTypes = {
  setShowFund: PropTypes.func.isRequired,
};

export default MainContent;
