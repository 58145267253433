import React, { useState, useEffect, useContext, useRef } from 'react';
import swal from 'sweetalert';
import { Modal, ModalBody } from 'reactstrap';
import { customBaseUrl, httpPost, httpPut } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';
import LinkBvn from '../../shared/Modals/Bvn';
import PinModal from '../../shared/Modals/PinModal';
import Button from '../../shared/Button';
import moment from 'moment';
import validateImage from '../../../utils/validateImage';
import { hideLoader, showLoader } from '../../../utils/loader';
import upload1 from '../../../assets/image/upload.svg';
import frontArrow from '../../../assets/image/front_arrow.svg';
import backArrow from '../../../assets/image/back_arrow.svg';
import check from '../../../assets/image/check.svg';
import pending from '../../../assets/image/pending.svg';
import rejected from '../../../assets/image/rejected.svg';

import Swal from 'sweetalert2';
import ViewDocModal from './viewDoc';
import { notification } from 'antd';

const initial = {
  // bankReference1: false,
  // bankReference1Url: '',
  // bankReference2: false,
  // bankReference2Url: '',
  cacCertificate: false,
  cacCertificateUrl: '',
  cacDocument: false,
  cacDocumentUrl: '',
  cacMemart: false,
  cacMemartUrl: '',
  taxDocument: false,
  taxDocumentUrl: '',
};
export default function CacTier4(props) {
  const inputDoc = useRef(null);
  const inputCert = useRef(null);
  const inputDir = useRef(null);
  const inputMem = useRef(null);
  const inputTin = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const { userId, businessId, getDocuments, documents, setTier4Status } = props;
  const [data, setData] = useState(initial);
  const [tin, setTin] = useState(null);
  const [doc, setDoc] = useState({});
  const [viewData, setViewData] = useState({});
  const [viewDocModal, setShowViewDocModal] = useState(false);
  const checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] === '') return false;
    }
    return true;
  };
  const handleSave = async (e) => {
    e.preventDefault();
    showLoader();
    if (!checkProperties(data))
      return Swal.fire('All document must be uploaded!');
    console.log('Sdata', data);
    const res = await httpPut(
      `/api/v1/kyc/cac/uploadOtherDocument/${businessId}`,
      data,
      customBaseUrl.kycUrl
    );
    if (res?.status) {
      hideLoader();
      getDocuments();
      Swal.fire('Saved', res?.message);
    } else {
      hideLoader();
      Swal.fire(' ', res?.message);
    }
  };
  const handlePreview = (e, type) => {
    const imageData = e.target.files[0];
    const validFormat = validateImage(imageData);
    if (validFormat.valid) {
      setImageFile(imageData);
      setData({ ...data, fileName: imageData?.name });
      const reader = new FileReader();
      reader.onloadend = () => {
        upload(imageData, type);
      };
      reader.readAsDataURL(imageData);
    } else {
      swal('Oops!', `${validFormat.message}`, 'error');
    }
  };
  const previewImg = (e) => {
    setViewData({ url: e });
    setShowViewDocModal(true);
  };
  const upload = async (file, type) => {
    showLoader();
    let newForm = new FormData();
    newForm.append('file', file);
    newForm.append('fileName', 'UTILITY');
    const res = await httpPost(
      `/upload/others/?fileName=${
        type === 'id' ? data?.idCardType : type
      }&userId=${userId}`,
      newForm,
      customBaseUrl.fileResourseUrl
    );
    if (res?.status) {
      hideLoader();
      Swal.fire('Done', res?.message);
      if (type === 'CAC_DOCUMENT') {
        setData({ ...data, cacDocument: true, cacDocumentUrl: res?.data });
      } else if (type === 'CAC_CERTIFICATE') {
        setData({
          ...data,
          cacCertificate: true,
          cacCertificateUrl: res?.data,
        });
      } else if (type === 'DIRECTOR_LIST') {
        setData({ ...data, directorListUrl: res?.data });
      } else if (type === 'CAC_MEMART') {
        setData({ ...data, cacMemart: true, cacMemartUrl: res?.data });
      } else if (type === 'CAC_TIN') {
        setData({ ...data, taxDocument: true, taxDocumentUrl: res?.data });
        console.log('url', res?.data);
      } else if (type === 'CAC_REFERENCE1') {
        setData({
          ...data,
          bankReference1: true,
          bankReference1Url: res?.data,
        });
        console.log('url', res?.data);
      } else if (type === 'CAC_REFERENCE2') {
        setData({
          ...data,
          bankReference2: true,
          bankReference2Url: res?.data,
        });
        console.log('url', res?.data);
      }
    } else {
      hideLoader();
      Swal.fire('Oops!', res?.message);
      /* if (type === 'CAC_DOCUMENT') {
        setData({ ...data, cacDocumentUrl: 'upload failed' });
      } else if (type === 'CAC_CERTIFICATE') {
        setData({ ...data, cacCertificateUrl: 'upload failed' });
      } else if (type === 'DIRECTOR_LIST') {
        setData({ ...data, directorListUrl: 'upload failed' });
      } else if (type === 'CAC_MEMART') {
        setData({ ...data, cacMemartUrl: 'upload failed' });
      } else if (type === 'CAC_TIN') {
        setData({ ...data, taxDocumentUrl: 'upload failed' });
      } else if (type === 'CAC_REFERENCE1') {
        setData({ ...data, bankReference1Url: 'upload failed' });
      } else if (type === 'CAC_REFERENCE2') {
        setData({ ...data, bankReference2Url: 'upload failed' });
      } */
      Swal.fire('', res?.message);
    }
  };

  useEffect(() => {
    if (documents?.length > 0) {
      const cacDoc = documents?.filter((e) => e?.docType === 'CAC_DOCUMENT')[0];
      const cert = documents?.filter(
        (e) => e?.docType === 'CAC_CERTIFICATE'
      )[0];
      const mem = documents?.filter((e) => e?.docType === 'CAC_MEMART')[0];
      const tax = documents?.filter((e) => e?.docType === 'TAX_DOCUMENT')[0];
      const ref1 = documents?.filter(
        (e) => e?.docType === 'BANK_REFERENCE_I'
      )[0];
      const ref2 = documents?.filter(
        (e) => e?.docType === 'BANK_REFERENCE_II'
      )[0];
      setDoc({ ...doc, cacDoc, cert, mem, tax, ref1, ref2 });
      setData({
        ...data,
        bankReference1Url: ref1?.docUrl,
        bankReference2Url: ref2?.docUrl,
        cacCertificateUrl: cert?.docUrl,
        cacDocumentUrl: cacDoc?.docUrl,
        cacMemartUrl: mem?.docUrl,
        taxDocumentUrl: tax?.docUrl,
      });
      if (cacDoc?.status === 'APPROVED' &&
        cert?.status === 'APPROVED' &&
        mem?.status === 'APPROVED' &&
        tax?.status === 'APPROVED'
      ) {
        setTier4Status(true);
      }
    } else {
      setData(initial);
    }
  }, [documents]);

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    upload(event.target.files[0], event.target.id);
    event.target.value = null;
  };

  return (
    <tbody>
      <tr className="">
        <td className="p-3">CAC Document </td>
        <td>
          <div
            type="file"
            // className="action btn my-2"
            className={`action my-2
              ${doc?.cacDoc?.status === 'APPROVED' ||
                doc?.cacDoc?.status === 'UNDER_REVIEW' ?
                  'disabled btn': 'btn'
              }
            `}
            onClick={() => inputDoc.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputDoc}
              type="file"
              id="CAC_DOCUMENT"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.cacDocumentUrl?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.cacDocumentUrl}
                width={30}
                height={30}
                onClick={() => previewImg(data?.cacDocumentUrl)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.cacDoc?.status === 'APPROVED'
                ? 'status-true'
                : doc?.cacDoc?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.cacDoc?.status === 'APPROVED'
                  ? check
                  : doc?.cacDoc?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.cacDoc?.status}</span>
            <p>
              {doc?.cacDoc?.status === 'REJECTED' ? doc?.cacDoc?.reason : ''}
            </p>
          </div>
        </td>
      </tr>
      <tr className="">
        <td className="p-3">CAC CERTIFICATE </td>
        <td>
          <div
            type="file"
            // className="action btn my-2"
            className={`action my-2
              ${doc?.cert?.status === 'APPROVED' ||
                doc?.cert?.status === 'UNDER_REVIEW' ?
                  'disabled btn': 'btn'
              }
            `}
            onClick={() => inputCert.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputCert}
              type="file"
              id="CAC_CERTIFICATE"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.cacCertificateUrl?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.cacCertificateUrl}
                width={30}
                height={30}
                onClick={() => previewImg(data?.cacCertificateUrl)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.cert?.status === 'APPROVED'
                ? 'status-true'
                : doc?.cert?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.cert?.status === 'APPROVED'
                  ? check
                  : doc?.cert?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.cert?.status}</span>
            <p>{doc?.cert?.status === 'REJECTED' ? doc?.cert?.reason : ''}</p>
          </div>
        </td>
      </tr>
      {/* <tr className="">
        <td className="p-3">CAC DIRECTOR LIST </td>
        <td>
          <div
            type="file"
            className="action btn my-2"
            onClick={() => inputDir.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputDir}
              type="file"
              id="DIRECTOR_LIST"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.directorListUrl?.includes('http') ? (
              <img src={check} alt="" />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              'status-pending'
              /* cac?.status === 'APPROVED'
                ? 'status-true'
                : 'status-pending'
            }
          >
            <img
              src={
                pending
                /* cac?.status === 'APPROVED'
                  ? check
                  : cac?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>PENDING</span>
          </div>
        </td>
        </tr> */}
      <tr className="">
        <td className="p-3">CAC Document-MEMART </td>
        <td>
          <div
            type="file"
            // className="action btn my-2"
            className={`action my-2
              ${doc?.mem?.status === 'APPROVED' ||
                doc?.mem?.status === 'UNDER_REVIEW' ?
                  'disabled btn': 'btn'
              }
            `}
            onClick={() => inputMem.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputMem}
              type="file"
              id="CAC_MEMART"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.cacMemartUrl?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.cacMemartUrl}
                width={30}
                height={30}
                onClick={() => previewImg(data?.cacMemartUrl)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.mem?.status === 'APPROVED'
                ? 'status-true'
                : doc?.mem?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.mem?.status === 'APPROVED'
                  ? check
                  : doc?.mem?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.mem?.status}</span>
            <p>{doc?.mem?.status === 'REJECTED' ? doc?.mem?.reason : ''}</p>
          </div>
        </td>
      </tr>
      <tr className="">
        <td className="p-3">TAX IDENTIFICATION NUMBER (TIN) </td>
        <td>
          {/* <div
            type="file"
            // className="action btn my-2"
            className={`action my-2
              ${doc?.tax?.status === 'APPROVED' ||
                doc?.tax?.status === 'UNDER_REVIEW' ?
                  'disabled btn': 'btn'
              }
            `}
            onClick={() => inputTin.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputTin}
              type="file"
              id="CAC_TIN"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div> */}
          <div>
            <input
              type='number'
              onChange={(e) => {
                setTin(e.target.value);
                setData({ ...data, taxDocument: true, taxDocumentUrl: e.target.value });
              }}
              value={tin ?? data?.taxDocumentUrl}
            />
          </div>
          <span>
            {data?.taxDocumentUrl?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.taxDocumentUrl}
                width={30}
                height={30}
                onClick={() => previewImg(data?.taxDocumentUrl)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.tax?.status === 'APPROVED'
                ? 'status-true'
                : doc?.tax?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.tax?.status === 'APPROVED'
                  ? check
                  : doc?.tax?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.tax?.status}</span>
            <p>{doc?.tax?.status === 'REJECTED' ? doc?.tax?.reason : ''}</p>
          </div>
        </td>
      </tr>
      <tr className="">
        <td className="p-3">BANK REFERENCE 1 
          <label style={{ fontSize: 10, color: 'gray' }}> *optional</label>
        </td>
        <td>
          <div
            type="file"
            // className="action btn my-2"
            className={`action my-2
              ${doc?.ref1?.status === 'APPROVED' ||
                doc?.ref1?.status === 'UNDER_REVIEW' ?
                  'disabled btn': 'btn'
              }
            `}
            onClick={() => inputRef1.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputRef1}
              type="file"
              id="CAC_REFERENCE1"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.bankReference1Url?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.bankReference1Url}
                width={30}
                height={30}
                onClick={() => previewImg(data?.bankReference1Url)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.ref1?.status === 'APPROVED'
                ? 'status-true'
                : doc?.ref1?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.ref1?.status === 'APPROVED'
                  ? check
                  : doc?.ref1?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.ref1?.status}</span>
            <p>{doc?.ref1?.status === 'REJECTED' ? doc?.ref1?.reason : ''}</p>
          </div>
        </td>
      </tr>
      {/*<tr className="">
        <td className="p-3">BANK REFERENCE 2 </td>
        <td>
          <div
            type="file"
            className="action btn my-2"
            onClick={() => inputRef2.current.click()}
          >
            <img src={upload1} alt="" className="me-2" />
            <input
              style={{ display: 'none' }}
              ref={inputRef2}
              type="file"
              id="CAC_REFERENCE2"
              onChange={handleFileChange}
            />
            <span>Upload</span>
          </div>
          <span>
            {data?.bankReference2Url?.includes('http') ? (
              <img
                className="mt-2"
                src={data?.bankReference2Url}
                width={30}
                height={30}
                onClick={() => previewImg(data?.bankReference2Url)}
              />
            ) : (
              <img src={pending} alt="" />
            )}
          </span>
        </td>
        <td>
          <div
            className={
              doc?.ref2?.status === 'APPROVED'
                ? 'status-true'
                : doc?.ref2?.status === 'REJECTED'
                ? 'status-false'
                : 'status-pending'
            }
          >
            <img
              src={
                doc?.ref2?.status === 'APPROVED'
                  ? check
                  : doc?.ref2?.status === 'REJECTED'
                  ? rejected
                  : pending
              }
              alt=""
              className="me-2"
            />
            <span>{doc?.ref2?.status}</span>
            <p>{doc?.ref2?.status === 'REJECTED' ? doc?.ref2?.reason : ''}</p>
          </div>
        </td>
      </tr> */}
      <div style={{ textAlign: 'right' }}>
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </div>
      {viewDocModal ? (
        <ViewDocModal
          showModal={viewDocModal}
          hideModal={setShowViewDocModal}
          data={viewData}
        />
      ) : (
        ''
      )}
    </tbody>
  );
}
