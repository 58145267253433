import axios from "axios";
import { verifyWayaQuickTransfer } from "../../plugins/urls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const IFrame = ({ source, transId }) => {
  const navigate = useNavigate();
  const verifyTransaction = () => {
    axios.post(`${verifyWayaQuickTransfer}?transId=${transId}`).then((res) => {
      if (res.status === 200) {
        console.log(res);
      }
    });
  };
  useEffect(() => {
    return () => {
      if (transId) {
        verifyTransaction();
      }
    };
  }, [transId]);

  return navigate({ to: source });
};
